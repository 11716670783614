import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Posterior",
  "description": "JAMStack Store",
  "order": 6,
  "thumb": "assets/banner.png",
  "slug": "posterior",
  "client": "Personal Project",
  "category": "eCommerce",
  "tools": "React, Gatsby, Node.js",
  "year": 2021,
  "github": "https://github.com/jlnbxn/posterior",
  "website": "https://posterior.netlify.app/",
  "backgroundColor": "#FCF9D2",
  "summary": "A completely static React eCommerce application, implementing Stripe's API and a carefully selected collection of beautiful public domain images."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "className": "content-section"
    }}><h2 parentName="div">{`The Problem`}</h2><div parentName="div" {...{
        "className": "content-section-text"
      }}><p parentName="div">{`Despite the JAMStack's steady increase in popularity, there's a lack of examplary eCommerce platforms utilizing it. Lots of sites could benefit from such a change in their architecture when it comes to speed, reliability, and security.`}</p></div></div>
    <div {...{
      "className": "content-section"
    }}><h2 parentName="div">{`The Solution`}</h2><div parentName="div" {...{
        "className": "content-section-text"
      }}><p parentName="div">{`Create a proof of concept poster store site to demonstrate the capabilities of a serverless approach for selling goods online. At the same time, try to find and scrape a source for CC0 images.`}</p></div></div>
    <div {...{
      "className": "content-section"
    }}><h2 parentName="div">{`Outcome`}</h2><div parentName="div" {...{
        "className": "content-section-text"
      }}><p parentName="div">{`Posterior is a mock online poster store selling, using Stripe’s API to handle the checkout process. Its database consists of a single .json file, using a collection of high-quality public domain images to create an appealing demonstration for a potential production site.`}</p><p parentName="div">{`It is heavily inspired by `}<a parentName="p" {...{
            "href": "https://www.riseart.com/"
          }}>{`riseart.com`}</a>{`, employing a similar design and functionality. For example, filters for image properties such as price, color, artist, and orientation. In addition, each product comes with a list of keywords that allow for topical searches.`}</p><p parentName="div">{`This demo also includes a shopping cart, making basket items persist across sessions.`}</p><figure parentName="div" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "2000px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "70%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Demo of filters",
              "title": "Filter by a variety of the images` attributes.",
              "src": "/static/d20653010d96758788f3cbee293b4c33/f97d7/filter-by-attributes.png",
              "srcSet": ["/static/d20653010d96758788f3cbee293b4c33/0eb09/filter-by-attributes.png 500w", "/static/d20653010d96758788f3cbee293b4c33/1263b/filter-by-attributes.png 1000w", "/static/d20653010d96758788f3cbee293b4c33/f97d7/filter-by-attributes.png 2000w", "/static/d20653010d96758788f3cbee293b4c33/49142/filter-by-attributes.png 3000w", "/static/d20653010d96758788f3cbee293b4c33/1ce76/filter-by-attributes.png 4000w", "/static/d20653010d96758788f3cbee293b4c33/93515/filter-by-attributes.png 5637w"],
              "sizes": "(max-width: 2000px) 100vw, 2000px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Filter by a variety of the images\` attributes.`}</figcaption>{`
  `}</figure><figure parentName="div" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1879px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "70%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Demo of filtering by color",
              "title": "Quickly find images containing pink from 1920's french art magazine Art-Goût-Beauté",
              "src": "/static/b3086611edd979a7ad4ed3b066439d64/7ca0a/filter-by-pink.png",
              "srcSet": ["/static/b3086611edd979a7ad4ed3b066439d64/0eb09/filter-by-pink.png 500w", "/static/b3086611edd979a7ad4ed3b066439d64/1263b/filter-by-pink.png 1000w", "/static/b3086611edd979a7ad4ed3b066439d64/7ca0a/filter-by-pink.png 1879w"],
              "sizes": "(max-width: 1879px) 100vw, 1879px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Quickly find images containing pink from 1920's french art magazine Art-Goût-Beauté`}</figcaption>{`
  `}</figure><figure parentName="div" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "2000px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "70%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "View of basket",
              "title": "Free shipping? What a steal!",
              "src": "/static/effd2abf37de74437a38158b3a651ae3/f97d7/basket.png",
              "srcSet": ["/static/effd2abf37de74437a38158b3a651ae3/0eb09/basket.png 500w", "/static/effd2abf37de74437a38158b3a651ae3/1263b/basket.png 1000w", "/static/effd2abf37de74437a38158b3a651ae3/f97d7/basket.png 2000w", "/static/effd2abf37de74437a38158b3a651ae3/49142/basket.png 3000w", "/static/effd2abf37de74437a38158b3a651ae3/1ce76/basket.png 4000w", "/static/effd2abf37de74437a38158b3a651ae3/93515/basket.png 5637w"],
              "sizes": "(max-width: 2000px) 100vw, 2000px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Free shipping? What a steal!`}</figcaption>{`
  `}</figure><figure parentName="div" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1879px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "70%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square view",
              "title": "Show images in a square ratio for more efficient space usage.",
              "src": "/static/223e49910e433863178c3d8e1fa57783/7ca0a/square-images.png",
              "srcSet": ["/static/223e49910e433863178c3d8e1fa57783/0eb09/square-images.png 500w", "/static/223e49910e433863178c3d8e1fa57783/1263b/square-images.png 1000w", "/static/223e49910e433863178c3d8e1fa57783/7ca0a/square-images.png 1879w"],
              "sizes": "(max-width: 1879px) 100vw, 1879px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Show images in a square ratio for more efficient space usage.`}</figcaption>{`
  `}</figure><figure parentName="div" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1879px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "70%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Stripe Checkout",
              "title": "Checkout using Stripe and a serverless function.",
              "src": "/static/bab76dc0b9a56b679442adf86cb38254/7ca0a/stripe-checkout.png",
              "srcSet": ["/static/bab76dc0b9a56b679442adf86cb38254/0eb09/stripe-checkout.png 500w", "/static/bab76dc0b9a56b679442adf86cb38254/1263b/stripe-checkout.png 1000w", "/static/bab76dc0b9a56b679442adf86cb38254/7ca0a/stripe-checkout.png 1879w"],
              "sizes": "(max-width: 1879px) 100vw, 1879px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Checkout using Stripe and a serverless function.`}</figcaption>{`
  `}</figure></div></div>
    <div {...{
      "className": "content-section"
    }}><h2 parentName="div">{`Background`}</h2><div parentName="div" {...{
        "className": "content-section-text"
      }}><p parentName="div">{`I have always wanted to create an eCommerce store that uses a static site in the background. When I started outlining such a project, the main struggle became finding a good source of fake product data that looks nice and got enough variety to make the shopping experience authentic.`}</p><p parentName="div">{`Upon searching for such a mock API, I first stumbled upon `}<a parentName="p" {...{
            "href": "https://fakestoreapi.com/"
          }}>{`Fake Store API`}</a>{`, though free, is slow and lacks somewhat of a theme in their product selection (as long as you are not trying to build a wish.com clone). Next up, I looked at `}<a parentName="p" {...{
            "href": "https://github.com/marak/Faker.js/"
          }}>{`Faker.js`}</a>{`, which was not quite right for me either, as it lacked images and its cloud-sourced model started requiring payment some time ago.`}</p><p parentName="div">{`Then it occurred to me that selling mock images `}<em parentName="p">{`themselves`}</em>{` could kill two birds with one stone.`}</p><p parentName="div">{`I decided to look for royalty-free image sites and learned something new right away: `}<em parentName="p">{`Royalty-free`}</em>{` does not mean you are allowed to resell them for commercial purposes (as in `}<em parentName="p">{`printing the motive on a t-shirt or mug`}</em>{`). While this was not my actual goal anyway, it still bothered me. Instead, what I needed were `}<em parentName="p">{`CC0`}</em>{`, or `}<em parentName="p">{`public domain`}</em>{` images worthy of being on public display.`}</p><p parentName="div">{`During my search, I stumbled upon `}<a parentName="p" {...{
            "href": "https://www.rawpixel.com/"
          }}>{`rawpixel.com`}</a>{`  and its `}<a parentName="p" {...{
            "href": "https://www.rawpixel.com/category/53/public-domain"
          }}>{`public domain board`}</a>{`, which had some of the highest quality free images, CC0 or not, I have yet to see on the web. While the site lists the source for each picture, they also digitally enhanced each one while keeping the CC0 license intact. (If you look up  the original image, you will find that rawpixel did a great job rejuvenating them.)`}</p><figure parentName="div" {...{
          "className": "gatsby-resp-image-figure",
          "style": {}
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "2000px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "62.8%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACvklEQVQ4y42O609TBxjGz1+1P8AvftqH+clEs2WaxURdlmxuicaoTEWmZVawLaDgpRe6Uug5FEsp5awXUPRAV20oUEAFwdJ7a9aegm1/5hTrB0OiT/LkvT3v+z5C9Z1KRVWpVCqUy2V2d3ep1+s0Go19qc1a3NnZQVVVsltr5NObNBogVHeqzYFG7VhrScu1XrVabbKlqdVqnw62nmhQq3v7AvtAE5VKJXK5HOl0mkwmQz6fp1AoNJc+10KDxscHgibe3t4mmUw2YyvX+toxjdlsllQq/an+XKtFTa+5Fqqq2vzcouak5aZFzWkun9ub76MrFotUKuqew1q9zpdQr73nayFElQj/JVaZX15DiS/zKDzG6/UVCm/fUtx8w//ZFLKyhDwfwfNYYSQQQYnFcXhdjAc9TClzRBZjjMhzbKRyCLLFgmw0EpP6CIa9nNNfZlDUk4iG2FxeIPnyBaNyGPOEj+GAhy6HB4/sZmC0nwumqxxp+4P2nm6u95qJrW0gXO24g/m8HulaO2P2W3gHjmLVf89dw1muG9qwOW4TfDaLcyqIJE9gG/fgn/ZiGRugo9+G6LPgsPdy+fY11pOvEQ4cPsG3x37nm0M/0X1Tj2S9gqnrBt8d13Hwhw7O6Gz0DElc7LtH210zZ3sGGPb7mY/PMPlUJKi40VtN/NL5F2ubGwj/LpcJJUoE4xmeLBbxzsbx+SUiM4O8mBkiuRph5eUKnc4QenGaO16FCWWJaGId54SPB3YrVtGEeURH6d0Wgv9ZlJB7CJ/VhCwOMhsQcU1JSJMOXL5/kGbCTM0/53SXxEmDm1O9Pv60T3NfTnDTZOPMpX5GRAOrgR8pbD1BeNTdScT4G0vOdiJOAzH3feZGjQSGjbx6+pDVqJ3w7CQ/d7v41eRENyjS55IYD0xis/2NaNHx0GEk5GunuL3AB8yHfwlcD9xaAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screenshot of Rawpixel board",
              "title": "Who knew that public domain images could be so beautiful?",
              "src": "/static/166f6d457c0f9ad514b19e964f123b37/f97d7/rawpixel-demo.png",
              "srcSet": ["/static/166f6d457c0f9ad514b19e964f123b37/0eb09/rawpixel-demo.png 500w", "/static/166f6d457c0f9ad514b19e964f123b37/1263b/rawpixel-demo.png 1000w", "/static/166f6d457c0f9ad514b19e964f123b37/f97d7/rawpixel-demo.png 2000w", "/static/166f6d457c0f9ad514b19e964f123b37/49142/rawpixel-demo.png 3000w", "/static/166f6d457c0f9ad514b19e964f123b37/0d3f6/rawpixel-demo.png 3154w"],
              "sizes": "(max-width: 2000px) 100vw, 2000px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Who knew that public domain images could be so beautiful?`}</figcaption>{`
  `}</figure><p parentName="div">{`With my product selection decided on, I started the scraping process with `}<a parentName="p" {...{
            "href": "https://github.com/puppeteer/puppeteer"
          }}>{`pupeteer`}</a>{`, the headless Chrome browser. Not long after that, I changed my approach, realizing the site itself must be using an API to make their requests. I used some `}<em parentName="p">{`inspect element`}</em>{` magic and dug around in the network tab, where I found multiple XHR requests that allowed insight into the querying of their database. To find out their exact endpoints, I also looked at the source code, which contained a handful of base URLs. Finding out the query parameters took some trial and error, though.`}</p><p parentName="div">{`Eventually, I succeeded, which made it possible to quickly scrape high-resolution images with much less overhead, in fact, the whole of their public domain collection. For folks as enthusiastic as me about those images, I made the scraper available at its own `}<a parentName="p" {...{
            "href": "https://github.com/jlnbxn/rawpixel-cc0-downloader"
          }}>{`GitHub repo`}</a>{`. The one I used for this project (which only downloads the small, web-friendly sizes) is called createProducts.js.`}</p><p parentName="div">{`To allow for the creation of a database, I figured it would be easiest to directly read the EXIF tags off those images (which contain things like keywords and description) and feed them into Gatsby using the `}<a parentName="p" {...{
            "href": "https://www.gatsbyjs.com/plugins/gatsby-plugin-sharp-exif/"
          }}>{`Gatsby Exif Source Plugin`}</a>{`. Sadly, said data only comes in the highest quality .jpeg and uncompressed .tiff files, each clocking in at around 30MB to 100MB per file, respectively (props to rawpixel for offering this for free!).`}</p><p parentName="div">{`However, I liked the idea of having only one file for each product. I thus decided to use a reasonably sized version of each image and write the metadata (provided by the API) to the files myself, using `}<a parentName="p" {...{
            "href": "https://github.com/hMatoba/piexifjs"
          }}>{`piexif`}</a>{`. That was before I realized how messy and unstandardized those tags are, compared to, say, ID3 tags. Alas, back to the boringly verbose .json file.`}</p><p parentName="div">{`To realize the vision of my store, I also needed things like the artist and year, which sadly is not provided by the API. I figured I had to extract those data from the only field that contained relevant information, mainly pinterest_description. Lacking a Ph.D. in Regex, I only got part of the image collection to parse correctly. Doing so shrank my dataset since I only chose to include the files with all fields filled out.`}</p><p parentName="div">{`To get a fake price for the images, I created a random number of cents between a sensible range, as required by Stripes data structure. Size and height were also just a matter of converting px to cm.`}</p><p parentName="div">{`The color attributes of the images stem from `}<a parentName="p" {...{
            "href": "https://lokeshdhakar.com/projects/color-thief/"
          }}>{`color thief`}</a>{`, which creates a color palette for each image and then runs a delta function comparing the lab values of the colors to a table of colors I copied from riseart.com. It then proceeds to use the nearest color to the one queried, which does not already appear in the list. `}</p><p parentName="div">{`Neatly, they also send some keywords with the API request, which makes using the search function feel very organic, though not of AI level quality.`}</p><p parentName="div">{`Designwise, Posterior is a direct copy of riseart.com, which uses a themed version of `}<a parentName="p" {...{
            "href": "https://ant.design/"
          }}>{`antdesign`}</a>{`, with Roboto as their main font. It took me by surprise, for the site looks much more expensive and tailor-made than that. Talk about the high quality that many open-source design resources and libraries have reached.`}</p><p parentName="div">{`For generating the site, I used my favorite static site generator `}<a parentName="p" {...{
            "href": "https://www.gatsbyjs.com/"
          }}>{`Gatsby`}</a>{`. Not at least for the gatsby-image-plugin, which gets to shine on a graphic-laden site with the plugin progressively loading images and creating blurred placeholders.`}</p></div></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      